import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/styles";
import { matchSorter } from "match-sorter";
import DiscreteSlider from "./DiscreteSlider";
import NotDiscreteSlider from "./NotDiscreteSlider";
import MultiSelector from "./MultiSelector";
import { useSelector, useDispatch } from "react-redux";

import {
  getSeries,
  searchWithSelectors,
  setSeriesChips,
  getAltColors,
  getColors,
  setAltColorsChips,
  getPrices,
  setPrice,
  setStock,
  setColorsChips,
} from "../advancedSearch/advancedSearchSlice";
const marks = [
  {
    value: 0,
    label: "Beställningsvara",
    searchValue: 0,
  },
  {
    value: 1,
    label: "Snart åter",
    searchValue: 0,
  },
  {
    value: 2,
    label: "Fåtal produkter",
    searchValue: 49,
  },
  {
    value: 3,
    label: "Över 50 m²",
    searchValue: 99,
  },
  {
    value: 4,
    label: "Över 100 m²",
    searchValue: 499,
  },
  {
    value: 5,
    label: "Över 500 m²",
    searchValue: 999,
  },
  {
    value: 6,
    label: "Över 1000 m²",
    searchValue: 99999,
  },
];

const priceMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 100,
    label: "100",
  },
  {
    value: 200,
    label: "200",
  },
  {
    value: 300,
    label: "300",
  },
  {
    value: 400,
    label: "400",
  },
  {
    value: 500,
    label: "500",
  },
  {
    value: 600,
    label: ">500",
  },
];

const AdvancedSearchComponent = (props) => {
  const { getTranslation, lang, showAdancedSearch, searchOnChange } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { stockStatistics } = props;

  const [sliderPriceMarks, setSliderPriceMarks] = React.useState([0, 100]);
  const [stockStatisticsValues, setStockStatisticsValues] = React.useState();
  const series = useSelector(getSeries);
  const altColor = useSelector(getAltColors);
  const colors = useSelector(getColors);
  const prices = useSelector(getPrices);
  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue);

  useEffect(() => {
    if (stockStatistics && stockStatistics.maxPriceExcl) {
      setStockStatisticsValues(stockStatistics);
      let temp = [];
      for (let i = 0; i < 7; i++) {
        let mark = {
          value: (stockStatistics.maxPriceExcl * i) / 6,
          label: (stockStatistics.maxPriceExcl * i) / 6,
        };
        temp.push(mark);
      }
      setSliderPriceMarks(temp);
    }
  }, [stockStatistics]);

  const handlePriceSlider = (value) => {
    dispatch(setPrice(value));
    dispatch(searchWithSelectors());
  };
  const handleStockSlider = (value) => {
    dispatch(
      setStock([
        marks.filter((mark) => mark.value === value[0])[0].searchValue,
        marks.filter((mark) => mark.value === value[1])[0].searchValue,
      ])
    );
    dispatch(searchWithSelectors());
  };
  return (
    <Collapse
      in={showAdancedSearch}
      timeout="auto"
      style={{ backgroundColor: theme.background.main }}
      unmountOnExit
      sx={{ p: "0 15px 0 15px" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          {colors && (
            <MultiSelector
              onChange={(value) => dispatch(setColorsChips(value))}
              onClose={() => dispatch(searchWithSelectors())}
              chips={colors.chips}
              label={getTranslation("COLOR", lang)}
              filterOptions={filterOptions}
              data={colors.selectable}
            ></MultiSelector>
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          {series && (
            <MultiSelector
              onChange={(value) => dispatch(setSeriesChips(value))}
              onClose={() => dispatch(searchWithSelectors())}
              chips={series.chips}
              label={getTranslation("SERIE_TEXT", lang)}
              filterOptions={filterOptions}
              data={series.selectable}
            ></MultiSelector>
          )}
        </Grid>
        <Grid item xs={12} lg={4}>
          {altColor && (
            <MultiSelector
              onChange={(value) => dispatch(setAltColorsChips(value))}
              onClose={() => dispatch(searchWithSelectors())}
              chips={altColor.chips}
              label={getTranslation("ALT_COLOR", lang)}
              filterOptions={filterOptions}
              data={altColor.selectable}
            ></MultiSelector>
          )}
        </Grid>

        <Grid item xs={4} sm={6}>
          <Typography
            sx={{ mb: 4 }}
            style={{ color: theme.text.main }}
            variant="h7"
            component="div"
          >
            Pris
          </Typography>
          <Grid
            sx={{
              color: theme.text.main,
              mb: 2,
              height: 300,
            }}
          >
            {prices && (
              <NotDiscreteSlider
                max={prices.totalMax}
                onSliderChange={(v) => handlePriceSlider(v)}
                step={100}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Typography
            sx={{ mb: 4 }}
            style={{ color: theme.text.main }}
            variant="h7"
            component="div"
          >
            Lagerstatus
          </Typography>
          <Grid
            sx={{
              color: theme.text.main,
              mt: "15px",
              height: 300,
            }}
          >
            {marks && marks.length > 0 && (
              <DiscreteSlider
                marks={marks}
                step={null}
                onSliderChange={(v) => handleStockSlider(v)}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Collapse>
  );
};
export default AdvancedSearchComponent;
