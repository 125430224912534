import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import HeaderComponent from "../headercomponent/headerComponent";
import Table from "../table/tableComponent";
import { tableCellClasses } from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import Container from "@material-ui/core/Container";
import translations from "../translations.json";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  searchWithSelectors,
  getStatus,
} from "../advancedSearch/advancedSearchSlice";
const cchTheme = createTheme({
  spacing: 8,
  background: {
    main: "#001C27",
    part: "#C8CCD0",
  },
  text: {
    main: "#FFFFFF",
    part: "#001C27",
  },
  logo: {
    url: "https://www.hoganaskakel.se/app/uploads/2021/05/hoganas-logo-PO-rgb-neg.png",
  },
});

const eversTheme = createTheme({
  spacing: 8,
  background: {
    main: "#001C27",
    part: "#C8CCD0",
  },
  text: {
    main: "#FFFFFF",
    part: "#001C27",
  },
  logo: {
    url: "https://www.hoganaskakel.se/app/uploads/sites/2/2021/05/evers-neg-po-e1620813474266.png",
  },
});

const theme = createTheme({
  spacing: 8,
  background: {
    main: "#ECEAE5",
    part: "#3F4B4E",
  },
  text: {
    main: "#000000",
    part: "#DDDDDD",
  },
  logo: {
    url: "https://www.colorama.se/Resources/Graphics/Logo.svg",
  },
});

export default function MainFrame() {
  const dispatch = useDispatch();
  const [articles, setArticles] = useState();
  const [stockStatistics, setStockStatistics] = useState();
  const [latLong, setLatLong] = useState({ lat: 1, long: 1 });
  const [brandTheme, setBrandTheme] = useState("CCH");
  const [lang, setLang] = useState("sv");
  const [currentTheme, setCurrentTheme] = useState(cchTheme);
  const [uniqueId, setUniqueId] = useState();
  const loading = useSelector(getStatus);
  const firstSearch = {
    selectColor: [{ label: "White" }],
    selectSerie: [{ label: "Basic" }],
    sliderStockValue: [0, 9999],
    sliderPriceValue: [0, 9999],
  };

  const getTranslation = (field, lang) => {
    if (
      translations.filter((a) => a.field === field).length === 0 ||
      translations
        .filter((a) => a.field === field)[0]
        .translations.filter((b) => b.lang === lang).length === 0
    ) {
      return field;
    }
    return translations
      .filter((a) => a.field === field)[0]
      .translations.filter((b) => b.lang === lang)[0].text;
  };

  const searchOnChange = async (
    selectColor,
    selectSerie,
    sliderStockValue,
    sliderPriceValue
  ) => {
    await dispatch(searchWithSelectors({})).unwrap();
  };

  const searchForParts = (searchString) => {
    logSearch(searchString);
  };

  const getStockStatistics = () => {
    fetch("https://ifs-pricelist-api.azurewebsites.net/getStockStatistics")
      .then((res) => res.json())
      .then((res) => {
        setStockStatistics(res[0]);
      });
  };

  const getUniqueId = async () => {
    let id = localStorage.getItem("uniqueId");
    if (id && id.length > 0) {
      setUniqueId(id);
      return { uniqueId: id };
    } else {
      return await fetch(
        "https://ifs-pricelist-api.azurewebsites.net/generateUniqueId"
      ).then((res) => res.json());
    }
  };

  const logSearch = async (searchString) => {
    const fetchedUniqueId = await getUniqueId();
    localStorage.setItem("uniqueId", fetchedUniqueId.uniqueId);
    setUniqueId(fetchedUniqueId.uniqueId);

    const data = {
      latitude: latLong.lat,
      longitude: latLong.long,
      searchString: searchString,
      brand: brandTheme,
      uniqueId: fetchedUniqueId.uniqueId,
    };

    await fetch("https://ifs-pricelist-api.azurewebsites.net/logSearch", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  };

  useEffect(() => {
    navigator.geolocation.watchPosition(function (position) {
      setLatLong({
        lat: position.coords.latitude,
        long: position.coords.longitude,
      });
    });
    const params = new URLSearchParams(window.location.search);
    const part = params.get("part");

    let searchString = "basic";
    if (part && part.length > 0) {
      searchString = part;
    } else if (
      localStorage.getItem("part") &&
      localStorage.getItem("part").length > 0
    ) {
      searchString = localStorage.getItem("part");
    }
    const url = window.location.href;

    if (url.toLowerCase().includes("evers")) {
      setCurrentTheme(eversTheme);
    }

    window.history.pushState("", "Title", "/");

    const brand = params.get("brand");

    if (brand && brand.length > 0) {
      setBrandTheme(brand);
      localStorage.setItem("brand", brand);
    }
    //searchForParts(searchString);
    searchOnChange(
      firstSearch.selectColor,
      firstSearch.selectSerie,
      firstSearch.sliderStockValue,
      firstSearch.sliderPriceValue
    );
  }, []);

  return (
    <Container>
      <ThemeProvider theme={currentTheme}>
        <Grid align="stretch">
          <HeaderComponent
            getTranslation={getTranslation}
            stockStatistics={stockStatistics}
            lang={lang}
          ></HeaderComponent>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
            articles={articles}
            priceQuery={true}
            partList={false}
            lang={lang}
            getTranslation={getTranslation}
          ></Table>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
            articles={articles}
            priceQuery={false}
            partList={true}
            lang={lang}
            getTranslation={getTranslation}
          ></Table>
        </Grid>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading === "idle" ? false : true}
          transitionDuration={{ enter: 1000, exit: 1000 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </ThemeProvider>
    </Container>
  );
}
