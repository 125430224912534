import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  addToPriceQuery,
  removeFromPriceQuery,
} from "../advancedSearch/advancedSearchSlice";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import SwipeableViews from "react-swipeable-views";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const images = [
  {
    label: "San Francisco – Oakland Bay Bridge, United States",
    imgPath:
      "https://pricelistimagebank.blob.core.windows.net/images/3800745100310.jpg",
  },
  {
    label: "Bird",
    imgPath:
      "https://pricelistimagebank.blob.core.windows.net/images/8023423179384.jpg",
  },
  {
    label: "Bali, Indonesia",
    imgPath:
      "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80",
  },
  {
    label: "Goč, Serbia",
    imgPath:
      "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  },
];
export default function DetailedPart(props) {
  const dispatch = useDispatch();
  const { part, priceQuery } = props;
  const { lang } = props;
  const { getTranslation } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const getQtyInStockText = (m2) => {
    if (m2 >= 1000) {
      return "OVER_1000";
    }
    if (m2 >= 500) {
      return "OVER_500";
    }
    if (m2 >= 100) {
      return "OVER_100";
    }
    if (m2 >= 50) {
      return "OVER_50";
    }
    if (m2 < 50) {
      return "FEW";
    }
    if (m2 < 1) {
      return "SOON";
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Box>
          <img
            onClick={handleOpen}
            src={
              "https://pricelistimagebank.blob.core.windows.net/images/" +
              part.gtin_no +
              ".jpg"
            }
            style={{
              display: "block",
              width: "auto",
              height: "auto",
              maxWidth: "100%",
              maxHeight: "90%",
              margin: "20px auto",
            }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src =
                "https://pricelistimagebank.blob.core.windows.net/images/missing_image.png";
            }}
            alt={part.id}
          />
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <SwipeableViews
              //axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {images.map((step, index) => (
                <div key={step.label}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <a
                      target="_blank"
                      href={step.imgPath}
                      rel="noopener noreferrer"
                    >
                      <img
                        src={step.imgPath}
                        style={{
                          display: "block",
                          width: "auto",
                          height: "auto",
                          maxWidth: "100%",
                          maxHeight: "100%",
                          margin: "20px auto",
                        }}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src =
                            "https://pricelistimagebank.blob.core.windows.net/images/missing_image.png";
                        }}
                        alt={part.id}
                      />
                    </a>
                  ) : null}
                </div>
              ))}
            </SwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  <KeyboardArrowLeft />
                  Back
                </Button>
              }
            />
          </Box>
        </Modal>
      </Grid>
      <Grid item xs={9}>
        <Grid container spacing={2} sx={{ marginTop: "2px" }}>
          <Grid item xs={6} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation("SERIE_TEXT", lang)}
              </Typography>
              <Typography
                variant="h6"
                sx={{ mt: -1 }}
                style={{ wordWrap: "break-word" }}
              >
                {part.serie}
              </Typography>
            </Paper>
          </Grid>
          {part.alt_color && part.alt_color.length > 0 && (
            <Grid item xs={12} lg={6}>
              <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  variant="body1"
                  style={{ wordWrap: "break-word" }}
                  component="div"
                >
                  {getTranslation("ALT_COLOR", lang)}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ mt: -1 }}
                  style={{ wordWrap: "break-word" }}
                >
                  {part.alt_color}
                </Typography>
              </Paper>
            </Grid>
          )}
          {part.color && part.color.length > 0 && (
            <Grid item xs={6} lg={6}>
              <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  variant="body1"
                  style={{ wordWrap: "break-word" }}
                  component="div"
                >
                  {getTranslation("COLOR", lang)}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ mt: -1 }}
                  style={{ wordWrap: "break-word" }}
                >
                  {part.color}
                </Typography>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} sm={3} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation("PRICE_TEXT", lang)}/
                {getTranslation(part.part_main_group, lang)} inkl moms
              </Typography>
              <Typography
                variant="h6"
                sx={{ mt: -1 }}
                style={{ wordWrap: "break-word" }}
              >
                {part.prices.price_se_inc.toLocaleString("sv-SE")}{" "}
                {getTranslation("SEK", lang)}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={3} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation("PRICE_TEXT", lang)}/
                {getTranslation(part.part_main_group, lang)} exkl moms
              </Typography>
              <Typography
                variant="h6"
                sx={{ mt: -1 }}
                style={{ wordWrap: "break-word" }}
              >
                {part.prices.price_se_ex.toLocaleString("sv-SE")}{" "}
                {getTranslation("SEK", lang)}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation("STOCK_STATUS", lang)}
              </Typography>
              <Typography
                variant="h6"
                sx={{ mt: -1 }}
                style={{ wordWrap: "break-word" }}
              >
                {part.range !== "40"
                  ? getTranslation(
                      getQtyInStockText(part.m2_in_stock.toFixed(0)),
                      lang
                    )
                  : getTranslation("ORDER_ITEM", lang)}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation("QUALITY", lang)}
              </Typography>
              <Typography
                variant="h6"
                sx={{ mt: -1 }}
                style={{ wordWrap: "break-word" }}
              >
                {part.text}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                Format
              </Typography>
              <Typography
                variant="h6"
                sx={{ mt: -1 }}
                style={{ wordWrap: "break-word" }}
              >
                {part.dim_quality} mm
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={6} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation(part.part_main_group, lang)}/
                {getTranslation("PACKAGE", lang)}
              </Typography>
              <Typography
                variant="h6"
                sx={{ mt: -1 }}
                style={{ wordWrap: "break-word" }}
              >
                {part.m2_per_package.toLocaleString("sv-SE")}{" "}
                {getTranslation(part.part_main_group, lang)}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={6} lg={6}>
            <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="body1"
                style={{ wordWrap: "break-word" }}
                component="div"
              >
                {getTranslation(part.part_main_group, lang)}/
                {getTranslation("PALL", lang)}
              </Typography>
              <Typography
                variant="h6"
                sx={{ mt: -1 }}
                style={{ wordWrap: "break-word" }}
              >
                {part.m2_per_pallet.toFixed(0)}{" "}
                {getTranslation(part.part_main_group, lang)}
              </Typography>
            </Paper>
          </Grid>

          {part.surface && part.surface !== "NA" && (
            <Grid item xs={6} lg={6}>
              <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  variant="body1"
                  style={{ wordWrap: "break-word" }}
                  component="div"
                >
                  {getTranslation("SURFACE", lang)}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ mt: -1 }}
                  style={{ wordWrap: "break-word" }}
                >
                  {getTranslation("SURFACE_" + part.surface, lang)}
                </Typography>
              </Paper>
            </Grid>
          )}

          {/*part.trim_piece && part.trim_piece.length > 0 && (
            <Grid item xs={6} lg={6}>
              <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  variant="body1"
                  style={{ wordWrap: "break-word" }}
                  component="div"
                >
                  {getTranslation("TRIM_PIECE", lang)}
                </Typography>
                <Typography variant="h6" sx={{ mt: -1 }} style={{ wordWrap: "break-word" }}>
                  {getTranslation("TRIM_" + part.trim_piece, lang)}
                </Typography>
              </Paper>
            </Grid>
          )*/}

          {part.appearance && part.appearance.length > 0 && (
            <Grid item xs={6} lg={6}>
              <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  variant="body1"
                  style={{ wordWrap: "break-word" }}
                  component="div"
                >
                  {getTranslation("APPEARANCE", lang)}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ mt: -1 }}
                  style={{ wordWrap: "break-word" }}
                >
                  {part.appearance}
                </Typography>
              </Paper>
            </Grid>
          )}

          {!priceQuery && (
            <Grid item xs={12} lg={6}>
              <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  variant="body1"
                  style={{ wordWrap: "break-word", color: "#B17F4A" }}
                  component="div"
                  onClick={() => dispatch(addToPriceQuery(part))}
                >
                  {getTranslation("ADD_TO_PRICE_QUERY", lang)}
                </Typography>
              </Paper>
            </Grid>
          )}
          {priceQuery && (
            <Grid item xs={12} lg={6}>
              <Paper sx={{ pl: 1, mt: -1 }} elevation={0}>
                <Typography
                  sx={{ fontWeight: "bold" }}
                  variant="body1"
                  style={{ wordWrap: "break-word", color: "#B17F4A" }}
                  component="div"
                  onClick={() => dispatch(removeFromPriceQuery(part))}
                >
                  {getTranslation("REMOVE_FROM_PRICE_QUERY", lang)}
                </Typography>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
