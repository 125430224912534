import React from "react";
import DetailedPart from "../detailedpart/detailedPart";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { withStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/styles";
import {
  updatePartInPriceQuery,
  getPriceQuerySum,updatePlacementInPriceQuery
} from "../advancedSearch/advancedSearchSlice";

const CustomTableCell = withStyles({
  root: {
    borderBottom: "0px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "0px",
    margin: "0px",
  },
})(TableCell);

function PartComponent(props) {
  const dispatch = useDispatch();
  const { article, windowDimensions, columns, placements } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { lang, priceQuery, partList } = props;
  const { getTranslation } = props;
  const priceQuerySum = useSelector(getPriceQuerySum);
  const getQtyInStockText = (m2) => {
    if (m2 >= 1000) {
      return "OVER_1000";
    }
    if (m2 >= 500) {
      return "OVER_500";
    }
    if (m2 >= 100) {
      return "OVER_100";
    }
    if (m2 >= 50) {
      return "OVER_50";
    }
    if (m2 < 50) {
      return "FEW";
    }
    if (m2 < 1) {
      return "SOON";
    }
  };



  const checkPartCol = (index) => {
    if (priceQuery) {
      return (
        windowDimensions.width > columns[index].minWindowWidth &&
        columns[index].priceQuery
      );
    }
    if (partList) {
      return (
        windowDimensions.width > columns[index].minWindowWidth &&
        columns[index].partList
      );
    }
  };

  const handleUpdatePriceQueryPart = (e, article) => {
    let value = e.target.value;
    dispatch(updatePartInPriceQuery({ value, article }));
  };

  const handlePlacements = (e, article) => {
    let value = e.target.value;
    dispatch(updatePlacementInPriceQuery({ value, article }));
  }

  const renderTypography = (text) => {
    return (
      <Typography
        sx={{ fontWeight: "bold" }}
        style={{ color: theme.text.part }}
        variant="h7"
        component="div"
      >
        {text}
      </Typography>
    );
  };
  return (
    <React.Fragment>
      <TableRow
        onClick={() => setOpen(!open)}
        sx={{ border: 0 }}
        style={{ backgroundColor: theme.background.part }}
      >
        {checkPartCol(0) && (
          <CustomTableCell align="left" style={{ padding: "0px" }}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </CustomTableCell>
        )}
        {checkPartCol(1) && (
          <CustomTableCell align="left">
            {renderTypography(article.id)}
          </CustomTableCell>
        )}
        {checkPartCol(2) && (
          <CustomTableCell align="left">
            {renderTypography(article.serie)}
          </CustomTableCell>
        )}
        {checkPartCol(3) && (
          <CustomTableCell align="left">
            {renderTypography(
              article.description +
                " " +
                (article.appearance && article.appearance !== "N/A" ? article.appearance : "")
            )}
          </CustomTableCell>
        )}
        {checkPartCol(4) && (
          <CustomTableCell align="left">
            {renderTypography(article.text)}
          </CustomTableCell>
        )}
        {checkPartCol(5) && (
          <CustomTableCell align="left">
            {renderTypography(
              article.range !== "40"
                ? getTranslation(
                    getQtyInStockText(article.m2_in_stock.toFixed(0)),
                    lang
                  )
                : getTranslation("ORDER_ITEM", lang)
            )}
          </CustomTableCell>
        )}
        {checkPartCol(6) && (
          <CustomTableCell align="left">
            {renderTypography(
              article.prices.price_se_inc.toLocaleString("sv-SE") +
                " " +
                getTranslation("SEK", lang)
            )}
          </CustomTableCell>
        )}
        {checkPartCol(7) && (
          <CustomTableCell align="left">
            {renderTypography(
              article.prices.price_se_ex.toLocaleString("sv-SE") +
                " " +
                getTranslation("SEK", lang)
            )}
          </CustomTableCell>
        )}
      </TableRow>
      <TableRow
        sx={{ border: 0 }}
        style={{ backgroundColor: theme.background.part }}
      >
        {checkPartCol(0) && (
          <CustomTableCell
            align="left"
            style={{ padding: "0px" }}
          ></CustomTableCell>
        )}
        {checkPartCol(8) && (
          <CustomTableCell align="left" onClick={(e) => e.stopPropagation()}>
            <TextField
              size="small"
              style={{ width: 100 }}
              id="outlined-number"
              type="number"
              value={article.wanted}
              onChange={(e) => handleUpdatePriceQueryPart(e, article)}
              variant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {getTranslation(article.part_main_group, lang)}
                  </InputAdornment>
                ),
              }}
            />
          </CustomTableCell>
        )}
        {checkPartCol(9) && (
          <CustomTableCell align="left" onClick={(e) => e.stopPropagation()}>
            <Typography
              sx={{ fontWeight: "bold" }}
              style={{ color: theme.text.part }}
              variant="h7"
              component="div"
            >
              {article.needToBuy}{" "}
              {getTranslation(article.part_main_group, lang)}
            </Typography>
          </CustomTableCell>
        )}
      </TableRow>
      <TableRow
        sx={{ border: 0 }}
        style={{ backgroundColor: theme.background.part }}
      >
        {checkPartCol(0) && (
          <CustomTableCell
            align="left"
            style={{ padding: "0px" }}
          ></CustomTableCell>
        )}
        {checkPartCol(8) && (
          <CustomTableCell align="left" onClick={(e) => e.stopPropagation()}>
            <FormControl variant="filled" sx={{ minWidth: 100 }}>
              <InputLabel id="demo-simple-select-filled-label">
                Placering
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                size="small"
                value={article.placement}
                onChange={(e)=> handlePlacements(e, article)}
              >
                {placements &&
                  placements.map((placement, index) => (
                    <MenuItem key={index} value={placement}>{getTranslation(placement, lang)}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </CustomTableCell>
        )}
        {checkPartCol(10) && (
          <CustomTableCell align="right" onClick={(e) => e.stopPropagation()}>
            <Typography
              sx={{ fontWeight: "bold" }}
              style={{ color: theme.text.part }}
              variant="h7"
              component="div"
            >
              {article.priceForParts} {getTranslation("SEK", lang)}
            </Typography>
          </CustomTableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 10, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <DetailedPart
              part={article}
              priceQuery={priceQuery}
              windowDimensions={windowDimensions}
              columns={columns}
              lang={lang}
              getTranslation={getTranslation}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default PartComponent;
