import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchPosts = createAsyncThunk("posts/fetchPosts", async () => {
  //const response = await client.get('/fakeApi/posts')
  //return response.data
});

export const addNewPost = createAsyncThunk(
  "posts/addNewPost",
  async (initialPost) => {
    //const response = await client.post('/fakeApi/posts', initialPost)
    //return response.data
  }
);

export const searchWithSelectors = createAsyncThunk(
  "advancedSearch/searchWithSelectors",
  async (initialData, { getState, rejectWithValue, dispatch }) => {
    // <-- destructure getState method
    const state = getState(); // <-- invoke and access state object
    try {
      let includeParts = true;
      const s = state.advancedSearch;
      if (
        s.series.chips.length === 0 &&
        s.altColors.chips.length === 0 &&
        s.colors.chips.length === 0
      ) {
        includeParts = false;
        //return rejectWithValue("Select at least one");
      }
      const data = {
        colors: s.colors.chips.map((a) => a.label),
        altColors: s.altColors.chips.map((a) => a.label),
        series: s.series.chips.map((a) => a.label),
        minPrice: s.price.currentMin,
        maxPrice: s.price.currentMax,
        minStock: s.stock.currentMin,
        maxStock: s.stock.currentMax,
        includeParts: includeParts,
      };

      const response = await fetch(
        "https://ifs-pricelist-api.azurewebsites.net/getCategoriesBySelectors",
        {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      let responseJSON = await response.json();

      return responseJSON;
    } catch (error) {}
  }
);

export const searchWithSearchBar = createAsyncThunk(
  "advancedSearch/searchWithSearchBar",
  async (initialData, { getState }) => {
    // <-- destructure getState method
    const state = getState(); // <-- invoke and access state object
    try {
      let searchString =
        "https://ifs-pricelist-api.azurewebsites.net/findPartsById?id=" +
        state.advancedSearch.searchBarValue;

      const response = await fetch(searchString);
      let responseJSON = await response.json();

      return responseJSON;
    } catch (error) {}
  }
);

export const advancedSearchSlice = createSlice({
  name: "advancedSearch",
  initialState: {
    value: 0,
    searchBarValue: "",
    status: "idle",
    error: null,
    series: {
      selectable: [],
      selectedOnDropDown: [],
      chips: [],
    },
    colors: {
      selectable: [],
      selectedOnDropDown: [],
      chips: [],
    },
    altColors: {
      selectable: [],
      selectedOnDropDown: [],
      chips: [],
    },
    priceQuery: {
      parts: [],
    },
    price: {
      totalMin: 0,
      totalMax: 5000,
      currentMin: 0,
      currentMax: 5000,
    },
    stock: {
      totalMin: 0,
      totalMax: 9999,
      currentMin: 0,
      currentMax: 99999,
    },
    parts: [],
  },
  reducers: {
    setSearchBarValue: (state, action) => {
      state.searchBarValue = action.payload;
    },
    setSeriesSelectedOnDropDown: (state, action) => {
      state.series.selectedOnDropDown = action.payload;
    },
    setSeriesChips: (state, action) => {
      state.series.chips = action.payload;
    },
    setAltColorsSelectedOnDropDown: (state, action) => {
      state.altColors.selectedOnDropDown = action.payload;
    },
    setAltColorsChips: (state, action) => {
      state.altColors.chips = action.payload;
    },
    setColorsChips: (state, action) => {
      state.colors.chips = action.payload;
    },
    setPrice: (state, action) => {
      state.price.currentMin = action.payload[0];
      state.price.currentMax = action.payload[1];
    },
    setStock: (state, action) => {
      state.stock.currentMin = action.payload[0];
      state.stock.currentMax = action.payload[1];
    },
    addToPriceQuery: (state, action) => {
      state.priceQuery.parts.push({
        ...action.payload,
        wanted: 1,
        placement: "OTHER",
        needToBuy: (
          Math.ceil(1 / action.payload.m2_per_package) *
          action.payload.m2_per_package
        ).toFixed(2),
        priceForParts: Math.round(
          Math.ceil(1 / action.payload.m2_per_package) *
            action.payload.m2_per_package *
            action.payload.prices.price_se_ex
        ),
      });
    },
    removeFromPriceQuery: (state, action) => {
      state.priceQuery.parts = state.priceQuery.parts.filter(
        (part) => part.id !== action.payload.id
      );
    },
    updatePartInPriceQuery: (state, action) => {
      state.priceQuery.parts = state.priceQuery.parts.map((obj) =>
        obj.id === action.payload.article.id
          ? {
              ...obj,
              wanted: action.payload.value,
              needToBuy: (
                Math.ceil(action.payload.value / obj.m2_per_package) *
                obj.m2_per_package
              ).toFixed(2),
              priceForParts: Math.round(
                Math.ceil(action.payload.value / obj.m2_per_package) *
                  obj.m2_per_package *
                  obj.prices.price_se_ex
              ),
            }
          : obj
      );
    },
    updatePlacementInPriceQuery: (state, action) => {
      state.priceQuery.parts = state.priceQuery.parts.map((obj) =>
        obj.id === action.payload.article.id
          ? {
              ...obj,
              placement: action.payload.value,
            }
          : obj
      );
    },
    sortTableOn: (state, action) => {
      state.status = "pending";
      const temp = [...state.parts];
      const sortedParts = temp.sort(
        dynamicSort(
          action.payload.sortId,
          action.payload.sub,
          action.payload.sortDir
        )
      );
      state.parts = temp;
      state.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(searchWithSelectors.fulfilled, (state, action) => {
        state.series.selectable = action.payload.detailedSearch.series;
        state.colors.selectable = action.payload.detailedSearch.colors;
        state.altColors.selectable = action.payload.detailedSearch.altColors;

        state.parts = action.payload.parts;

        state.status = "idle";
      })
      .addCase(searchWithSelectors.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(searchWithSelectors.rejected, (state, action) => {
        state.altColors.selectable = state.altColors.selectable.map((o) => ({
          ...o,
          valid: true,
        }));
        state.series.selectable = state.series.selectable.map((o) => ({
          ...o,
          valid: true,
        }));
        state.colors.selectable = state.colors.selectable.map((o) => ({
          ...o,
          valid: true,
        }));
        state.parts = [];
        state.status = "idle";
      })
      .addCase(searchWithSearchBar.fulfilled, (state, action) => {
        state.parts = action.payload;
        state.status = "idle";
      })
      .addCase(searchWithSearchBar.pending, (state, action) => {
        state.status = "pending";
      });
  },
});

export const {
  sortTableOn,
  setSearchBarValue,
  setSeriesSelectedOnDropDown,
  setSeriesChips,
  setColorsChips,
  setAltColorsChips,
  setAltColorsSelectedOnDropDown,
  setPrice,
  setStock,
  addToPriceQuery,
  removeFromPriceQuery,
  updatePartInPriceQuery,
  updatePlacementInPriceQuery,
} = advancedSearchSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const incrementAsync = (amount) => (dispatch) => {
  setTimeout(() => {
    //dispatch(incrementByAmount(amount));
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = (state) => state.advancedSearch.value;
export const getStatus = (state) => state.advancedSearch.status;
export const getParts = (state) => state.advancedSearch.parts;
export const getSearchBarValue = (state) => state.advancedSearch.searchBarValue;
export const getSeries = (state) => state.advancedSearch.series;
export const getAltColors = (state) => state.advancedSearch.altColors;
export const getPrices = (state) => state.advancedSearch.price;
export const getColors = (state) => state.advancedSearch.colors;
export const getPriceQueryParts = (state) =>
  state.advancedSearch.priceQuery.parts;
export const getPriceQuerySum = (state) =>
  state.advancedSearch.priceQuery.parts.reduce(
    (n, { priceForParts }) => n + priceForParts,
    0
  );

export default advancedSearchSlice.reducer;

function dynamicSort(property, sub, sortDir) {
  var sortOrder = sortDir;
  return function (a, b) {
    var result;
    if (sub) {
      result =
        a[sub][property] < b[sub][property]
          ? -1
          : a[sub][property] > b[sub][property]
          ? 1
          : 0;
    } else {
      result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    }
    return result * sortOrder;
  };
}
