import React from "react";
import { styled } from "@mui/material/styles";
import Slider from "@material-ui/core/Slider";

const PrettoSlider4 = styled(Slider)(({ props }) => ({
  width: "97%",
  marginRight: "15px",
  marginLeft: "5px",
  color: "#FFFFFF",
  height: 8,
  "& .MuiSlider-track": {
    height: "8px",
    borderRadius: "8px",
  },
  "& .MuiSlider-rail": {
    height: "8px",
    
    borderRadius: "8px",
  },
  "& .MuiSlider-mark": {
    display: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    marginTop: "-8px",
    backgroundColor: "#B17F4A",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    display: "none",
  },
  "& .MuiSlider-markLabel": {
    color: "#FFFFFF",
    transform: "none",

    '&[data-index="3"]': {
      transform: "translateX(-100%)",
    },
  },
}));

const PrettoSlider7 = styled(Slider)((props ) => ({
  width: "100%",

  color: "#FFFFFF",

  "& .MuiSlider-track": {
    width: "8px",
    borderRadius: "8px",
  },
  "& .MuiSlider-rail": {
    width: "8px",
    borderRadius: "8px",
    marginTop: "-8px",
  },
  "& .MuiSlider-mark": {
    display: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    marginLeft: "-8px",

    backgroundColor: "#B17F4A",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    display: "none",
  },
  "& .MuiSlider-markLabel": {
    color: "#FFFFFF",
    transform: props.trans===1 ? "translateX(10px)" : "none",
    '&[data-index="1"]': {
      //      transform: "translateY(-200%)",
    },
    '&[data-index="3"]': {
      //transform: "translateY(-200%)",
    },

    '&[data-index="5"]': {
      //transform: "translateY(-200%)",
    },
    '&[data-index="6"]': {
      transform: "translate(10px, 5px)",

    },
  },
}));

export default function DiscreteSlider(props) {
  const { marks, step, onSliderChange } = props;

  const handleChange = (event, newValue) => {
    onSliderChange(newValue);
  };

  const sliderToReturn = () => {
    
    if (marks.length === 4) {
      return (
        <PrettoSlider4
          getAriaLabel={() => "Temperature range"}
          defaultValue={[0, marks[marks.length - 1].value]}
          step={null}
          valueLabelDisplay="auto"
          marks={marks}
        />
      );
    }
    if (marks.length === 7) {
      return (
        <PrettoSlider7
          getAriaLabel={() => "Temperature range"}
          defaultValue={[0, marks[marks.length - 1].value]}
          step={step}
          valueLabelDisplay="auto"
          marks={marks}
        />
      );
    }
    return <div></div>;
  };

  return (
    <PrettoSlider7
      getAriaLabel={() => "Temperature range"}
      defaultValue={[0, marks[marks.length - 1].value]}
      step={step}
      valueLabelDisplay="auto"
      marks={marks}
      max={marks[marks.length - 1].value}
      sx={{
        '& input[type="range"]': {
          WebkitAppearance: 'slider-vertical',
        },
      }}
      trans="1"
      orientation="vertical"
      onChangeCommitted={handleChange}
    />
  );
}
