import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
//import Chip from "@mui/material/Chip";
import { withStyles } from "@material-ui/core/styles";
//import Autocomplete from "@mui/material/Autocomplete";
import { useTheme } from "@mui/styles";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { useDispatch } from 'react-redux'
import {setSearchBarValue, searchWithSearchBar} from '../advancedSearch/advancedSearchSlice'
//import { matchSorter } from "match-sorter";
//import parse from "autosuggest-highlight/parse";
//import match from "autosuggest-highlight/match";

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      background: "white",
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

const styles = {
  margin: "10px 0 0 15px",
  width: "10%",
  "&.MuiButton-root": {
    border: "2px white solid",
  },
  "&.MuiButton-text": {
    color: "white",
  },
  "&.MuiButton-contained": {
    color: "white",
  },
  "&.MuiButton-outlined": {
    color: "white",
  },
};

export default function SearchBar(props) {
  const {getTranslation, lang} = props;
  const theme = useTheme();
  const dispatch = useDispatch()
  
  const handleTextFieldKeyDown = (event) => {
    if (event && event.key) {
      switch (event.key) {
        case "Enter":
          dispatch(searchWithSearchBar());
          break;
        case "Escape":
          break;
        default:
          break;
      }
    }
  };

  return (
    <Box
      style={{ backgroundColor: theme.background.main}}
      sx={{ width: "100%" }}
    >
      <CustomTextField
        id="filled-basic"
        sx={{ width: "60%", m: "10px 0 0 15px" }}
        size="small"
        label={getTranslation("MAIN_SEARCH_LABEL", lang)}
        onChange={(event) => dispatch(setSearchBarValue((event.target.value)))}
        onKeyDown={(event) => handleTextFieldKeyDown(event)}
        InputLabelProps={{
          sx: {
            [`&.${inputLabelClasses.shrink}`]: {
              color: "white",
              transform: "translate(0px, -20px) scale(1)",
            },
          },
        }}
      /> 

      <Button
        variant="outlined"
        sx={styles}
        size="medium"
        onClick={() => dispatch(searchWithSearchBar())}
      >
        {getTranslation("MAIN_SEARCH_BUTTON", lang)}
      </Button>
    </Box>
  );
}
