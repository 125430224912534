import React from "react";
import { styled } from "@mui/material/styles";
import Slider from "@material-ui/core/Slider";

const PrettoSlider = styled(Slider)((props) => ({
  width: "100%",

  color: "#FFFFFF",
  width: 8,
  "& .MuiSlider-track": {
    width: "8px",
  },
  "& .MuiSlider-rail": {
    width: "8px",
    borderRadius: "8px",
    marginTop: "-8px",
  },

  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    marginLeft: "-8px",

    backgroundColor: "#B17F4A",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
  },
  "& .MuiSlider-valueLabel": {
    color: "#FFFFFF",
    "& > span ": {
      transform: "translate(45px, 38px) rotate(45deg) ",
    },
    "& > span > span": {
      color: "#B17F4A",
      transform: "rotate(-45deg)",
    },
  },
  "& .MuiSlider-mark": {
    display: "none",
  },
  "& .MuiSlider-markLabel": {
    color: "#FFFFFF",
    transform: "translateX(10px) translateY(5px)",
  },
}));

export default function NotDiscreteSlider(props) {
  const { marks, step, onSliderChange, max, defaultValue} = props;

  const handleChange = (event, newValue) => {
    onSliderChange(newValue);
  };

  return (
    <PrettoSlider
      defaultValue={[0, max]}
      step={step}
      valueLabelDisplay="on"
      marks={marks}
      max={max}
      sx={{
        '& input[type="range"]': {
          WebkitAppearance: "slider-vertical",
        },
      }}
      orientation="vertical"
      onChangeCommitted={handleChange}
    />
  );
}
