import React, { useState, useEffect } from "react";
import SearchBar from "../searchbar/searchBar";
import AdvancedSearchComponent from "../advancedSearch/advancedSearch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useSelector, useDispatch } from 'react-redux'
import {getSearchBarValue, setSearchBarValue} from '../advancedSearch/advancedSearchSlice'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const HeaderComponent = (props) => {
  const { getTranslation, lang, searchOnChange } = props;
  const [showAdancedSearch, setShowAdancedSearch] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch()
  const { stockStatistics } = props;

  return (
    <Grid
      pt={3}
      style={{ backgroundColor: theme.background.main, width: "100%" }}
    >
      <Collapse
        in={!showAdancedSearch}
        timeout="auto"
        style={{ backgroundColor: theme.background.main }}
        unmountOnExit
      >
        <SearchBar
          stockStatistics={stockStatistics}

          getTranslation={getTranslation}
          lang={lang}
        />
      </Collapse>

      <Box style={{ backgroundColor: theme.background.main }}>
        <Box onClick={() => setShowAdancedSearch(!showAdancedSearch)}>
          <Typography
            style={{ color: theme.text.main }}
            variant="h7"
            component="div"
            sx={{ m: "0 0 0 15px" }}
            hidden={!showAdancedSearch}
            onClick={() => setShowAdancedSearch(!showAdancedSearch)}
          >
            Dölj avancerad sök
            <KeyboardArrowUpIcon />
          </Typography>
          <Typography
            style={{ color: theme.text.main }}
            variant="h7"
            component="div"
            sx={{ m: "0 0 0 15px" }}
            hidden={showAdancedSearch}
            onClick={() => setShowAdancedSearch(!showAdancedSearch)}
          >
            Visa avancerad sök <KeyboardArrowDownIcon />
          </Typography>
        </Box>
        <AdvancedSearchComponent
          showAdancedSearch={showAdancedSearch}
          stockStatistics={stockStatistics}
          searchOnChange={searchOnChange}
          lang={lang}
          getTranslation={getTranslation}
        />
      </Box>
    </Grid>
  );
};

export default HeaderComponent;
