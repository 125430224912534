import React from "react";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import CancelIcon from "@material-ui/icons/Cancel";

import { useDispatch } from "react-redux";
import {
  searchWithSelectors,
} from "../advancedSearch/advancedSearchSlice";

const MultiSelector = (props) => {
  const { onClose, onChange, label, filterOptions, data, onChipDelete, chips } =
    props;
  const theme = useTheme();
  const [textFieldInputValue, setTextFieldInputValue] = React.useState("");
  const dispatch = useDispatch();
  const handleOnChange = (e, value, situation, option) => {
    onChange(value);
    if (situation === "removeOption") {
      onClose();
    }
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        style={{ backgroundColor: theme.text.main }}
        options={data}
        //getOptionLabel={(option) => option.label}
        //filterOptions={filterOptions}
        onChange={(e, value, situation, option) =>
          handleOnChange(e, value, situation, option)
        }
        inputValue={textFieldInputValue}
        value={chips}
        onInputChange={(event, value, reason) => {
          if (event && event.type === "blur") {
            setTextFieldInputValue("");
          } else if (reason !== "reset") {
            setTextFieldInputValue(value);
          }
        }}
        onClose={() => {
          dispatch(searchWithSelectors());
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={label}
            placeholder=""
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option.label, inputValue);
          const parts = parse(option.label, matches);

          return (
            <li {...props} style={{}}>
              <div>
                <span
                  style={{
                    //fontWeight: part.highlight ? 900 : 600,
                    color: option.valid ? "#001C27" : "#B17F4A",
                  }}
                >
                  {option.label}
                </span>
              </div>
            </li>
          );
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.label}
              size="small"
              {...getTagProps({ index })}
              sx={{
                backgroundColor: theme.background.main,
                color: theme.text.main,
              }}
              deleteIcon={<CancelIcon style={{ color: theme.text.main }} />}
            />
          ))
        }
      ></Autocomplete>
    </FormControl>
  );
};
export default MultiSelector;
