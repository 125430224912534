import React, { useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Part from "../part/part";
import Typography from "@mui/material/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useTheme } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  getParts,
  sortTableOn,
  getPriceQueryParts,
} from "../advancedSearch/advancedSearchSlice";

const columns = [
  {
    id: "arrow",
    label: "",
    minWindowWidth: "0",
    priceQuery: false,
    priceQueryHeader: false,
    partList: true,
  },
  {
    id: "partNo",
    label: "Artikel",
    minWindowWidth: "0",
    sortId: "id",
    priceQuery: true,
    priceQueryHeader: false,
    partList: true,
  },
  {
    id: "serie",
    label: "Serie",
    minWindowWidth: "800",
    align: "left",
    sortId: "serie",
    priceQuery: false,
    priceQueryHeader: false,
    partList: true,
  },
  {
    id: "description",
    label: "Beskrivning",
    minWindowWidth: "0",
    align: "left",
    sortId: "description",
    priceQuery: true,
    priceQueryHeader: false,
    partList: true,
  },
  {
    id: "type",
    label: "Typ",
    minWindowWidth: "800",
    align: "left",
    sortId: "text",
    priceQuery: false,
    priceQueryHeader: false,
    partList: true,
  },
  {
    id: "stock",
    label: "Lagerstatus",
    minWindowWidth: "800",
    align: "left",
    sortId: "m2_in_stock",
    priceQuery: false,
    priceQueryHeader: false,
    partList: true,
  },
  {
    id: "priceInc",
    label: "Pris inkl.moms",
    minWindowWidth: "800",
    align: "left",
    sortId: "price_se_inc",
    subSortId: "prices",
    priceQuery: false,
    priceQueryHeader: false,
    partList: true,
  },
  {
    id: "priceEx",
    label: "Pris exkl.moms",
    minWindowWidth: "0",
    align: "left",
    sortId: "price_se_ex",
    subSortId: "prices",
    priceQuery: false,
    priceQueryHeader: false,
    partList: true,
  },
  {
    id: "wanted",
    label: "Antal",
    minWindowWidth: "0",
    align: "left",
    sortId: "wanted",
    priceQuery: true,
    priceQueryHeader: false,
    partList: false,
  },
  {
    id: "needToBuy",
    label: "Behöver köpa",
    minWindowWidth: "0",
    align: "left",
    sortId: "needToBuy",
    priceQuery: true,
    priceQueryHeader: false,
    partList: false,
  },
  {
    id: "sumWanted",
    label: "Summa",
    minWindowWidth: "0",
    align: "left",
    sortId: "sumWanted",
    priceQuery: true,
    priceQueryHeader: false,
    partList: false,
  },
  {
    id: "priceQueryHeader",
    label: "PRICE_QUERY_LABEL",
    minWindowWidth: "0",
    align: "left",
    sortId: "PriceQUeryHeader",
    priceQuery: false,
    priceQueryHeader: true,
    partList: false,
  },
  {
    id: "priceQueryHeaderPlaceholder1",
    label: "",
    minWindowWidth: "0",
    align: "left",
    sortId: "priceQueryHeaderPlaceholder1",
    priceQuery: false,
    priceQueryHeader: true,
    partList: false,
  },
];

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function TableComponent(props) {
  const dispatch = useDispatch();
  const { lang } = props;
  const { getTranslation, priceQuery, partList } = props;
  const theme = useTheme();
  const [sortDir, setSortDir] = useState(-1);
  const [sortCol, setSortCol] = useState();
  const parts = useSelector(getParts);
  const priceQueryParts = useSelector(getPriceQueryParts);
  const placements = ["FLOOR", "WALL", "ROOF", "OTHER"];

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const handleSortTable = (sortId, sub, sortDir) => {
    setSortCol(sortId);
    setSortDir(-1 * sortDir);
    dispatch(sortTableOn({ sortId, sub, sortDir }));
  };

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderTableCols = () => {
    let colsToUse = [];
    if (priceQuery) {
      colsToUse = columns.filter((col) => col.priceQueryHeader);
    } else {
      colsToUse = columns.filter((col) => col.partList);
    }
    return colsToUse.map(
      (column) =>
        windowDimensions.width > column.minWindowWidth && (
          <TableCell
            sx={{ p: "0 0 0 0" }}
            key={column.id}
            align={column.align}
            style={{ color: theme.text.main, padding: "0px 0px 0px 0px" }}
            onClick={() =>
              handleSortTable(column.sortId, column.subSortId, sortDir)
            }
          >
            {getTranslation(column.label, lang)}
            {sortCol && column.sortId === sortCol ? (
              sortDir === 1 ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )
            ) : (
              ""
            )}
          </TableCell>
        )
    );
  };

  const renderParts = () => {
    let partsToUse = [];
    if (priceQuery) {
      partsToUse = priceQueryParts;
    } else {
      partsToUse = parts;
    }
    return (
      partsToUse &&
      partsToUse.length > 0 &&
      partsToUse.map((article) => (
        <Part
          placements={placements}
          key={article.id}
          article={article}
          windowDimensions={windowDimensions}
          columns={columns}
          lang={lang}
          getTranslation={getTranslation}
          priceQuery={priceQuery}
          partList={partList}
        ></Part>
      ))
    );
  };

  const renderTabel = () => {
    let returnTabel = false;
    if (priceQuery && priceQueryParts && priceQueryParts.length > 0) {
      returnTabel = true;
    }
    if (partList && parts && parts.length > 0) {
      returnTabel = true;
    }
    return returnTabel ? (
      <div>
        <Table sx={{ width: "100%", borderColor: "#FF0000" }}>
          <TableHead style={{ backgroundColor: theme.background.main }}>
            <TableRow>{renderTableCols()}</TableRow>
          </TableHead>
          <TableBody>{renderParts()}</TableBody>
        </Table>
        {priceQuery && (
          <Table
            size="small"
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableHead
              style={{ backgroundColor: theme.background.main }}
            ></TableHead>
            <TableBody>
              {placements &&
                placements.map((place, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      height: "10",
                      paddingBottom: "0px",
                      paddingTop: "0px",
                    }}
                  >
                    <TableCell
                      align="right"
                      sx={{
                        borderBottom: "none",
                        height: "10",
                        paddingBottom: "0px",
                        paddingTop: "0px",
                      }}
                    >
                      {getTranslation(place, lang)}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        borderBottom: "none",
                        height: "10",
                        paddingBottom: "0px",
                        paddingTop: "0px",
                      }}
                    >
                      {priceQueryParts
                        .filter((a) => a.placement === place)
                        .reduce((n, { priceForParts }) => n + priceForParts, 0)} {getTranslation("SEK", lang)}
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell align="right">
                  <Typography
                    sx={{ fontWeight: "bold" }}
                    style={{ color: theme.text.part }}
                    variant="h7"
                    component="div"
                  >
                    Summa:
                  </Typography>
                </TableCell>
                <TableCell align="right">
                <Typography
                    sx={{ fontWeight: "bold" }}
                    style={{ color: theme.text.part }}
                    variant="h7"
                    component="div"
                  >
                    {priceQueryParts.reduce(
                    (n, { priceForParts }) => n + priceForParts,
                    0
                  ) } {getTranslation("SEK", lang)}
                  </Typography>
                  
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </div>
    ) : (
      <div></div>
    );
  };

  return <div>{renderTabel()}</div>;
}
